textarea {
  padding: 12px;
}

input {
  padding: 12px 12px 12px 30px;
}

.contact-strapline {
  font-size: 1.2em;
  color: grey;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contact-headline {
  font-size: 2em;
}
#contact-email {
  font-size: 4em;
}
.contact-section-hr {
  margin: 30px 0px 30px;
}

/* #nates-details::before {
  content: "";
  width: 30%;
  height: 3px;
  position: absolute;
  left: -106px;
  background-color: var(--main-font-color);
  top: 13px;
}

#nates-details::after {
  content: "";
  width: 30%;
  height: 3px;
  position: absolute;
  left: 215px;
  background-color: var(--main-font-color);
  top: 13px;
}

{
  position: "relative",
  top: "-116px",
  left: 146,
  transform: "rotate(270deg)",
  fontSize: "1.5em",
  letterSpacing: 5,
  minWidth: "270px",
} */
