.pls {
  position: absolute;
  width: 195px;
  height: 70px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 7px;
}

.pls::before,
.pls::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: inherit;
  z-index: -1;
  opacity: 0.7;
  border-radius: 7px;
}

.pls::before {
  animation: p 1s ease-in infinite;
}

/* .pls::after {
  animation: p 1.2s ease-out infinite;
} */

@keyframes p {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
