header {
  display: flex;
  justify-content: space-between;
  color: var(--main-font-color);
  font-family: "Bruno Ace";
  width: 100%;
}

.h-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.name-logo {
  text-transform: uppercase;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.nav-link {
  transition: color 0.3s, filter 0.3s 0.2s;
}
.nav-link:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 30px rgb(201, 122, 138));
  color: rgb(201, 122, 138);
}

#cor {
  width: 82px;

  /* font-family: "Rajdhani"; */
  font-style: normal;
  font-weight: 400;
  font-size: 37px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.04em;
}

#bitt {
  position: relative;
  width: 91px;
  top: -15px;
  /* font-family: "Rajdhani"; */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.17em;
}
