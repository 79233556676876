.hi-light-blue {
  color: rgb(56, 56, 245);
  text-shadow: 0px 0px 10px rgb(56, 56, 245);
  letter-spacing: 1px;
}

.restructuring_definition {
  position: absolute;
  border: 3px solid black;
  width: 23em;
  height: 11em;
  padding: 1em;
  background: #3e323a;
  z-index: 1;
  align-self: center;
}

.restructuring_definition dfn {
  text-decoration: underline;
}

.restructuring_definition p {
  line-height: 1.6em;
  margin-top: 1em;
}

.close_definition {
  float: right;
  cursor: pointer;
}

.restructuring {
  text-decoration: underline;
}
