code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bruno Ace";
  src: url(/static/media/BrunoAce-Regular.62f62c80.ttf);
}

:root {
  --main-font-color: #ffffff;
  --drk-red: #4c2e34;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(165.89deg, #2a3646 -9.08%, #4c2e34 130.86%);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Bruno Ace";
  font-size: 1em;
  padding: 45px 0px 45px 0px;
}

.app-container {
  display: flex;
  flex-flow: column wrap;
  position: relative;
  width: 90%;
  height: 100%;
  /* border: 1px solid white; */
}

#error-page {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

.section-height-controller {
  height: 920px;
}

#arrow-up-square {
  color: white;
  position: fixed;
  left: 96%;
  top: 93%;
  z-index: 3;
  cursor: pointer;
}

#arrow-up-square:hover {
  /* animation: name duration timing-function delay iteration-count direction fill-mode;; */
  animation: pulse 0.7s ease-in;
}

.pulse {
  animation: pulse 0.7s ease-in;
}

#skills-section,
#about-section,
#contact-section {
  height: auto;
  margin: 7% 0%;
}

#hero {
  height: 600px;
  margin: 5% 0% 0% 0%;
}

.skill,
#about-me-p {
  background: linear-gradient(45deg, #4a2323, transparent);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (max-width: 1453px) {
  #type-of-dev {
    font-size: 2.889em;
  }
  #strapline {
    top: 35px;
    font-size: 0.875em;
  }
}
@media screen and (max-width: 1440px) {
}

@media only screen and (min-width: 1081px) and (max-width: 1206px) {
  #type-of-dev {
    margin: 45px 0px 45px -3px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  #type-of-dev {
    font-size: 2.5em;
    line-height: 50px;
    margin: 45px 0px 45px -3px;
  }
}

@media only screen and (max-width: 1024px) {
  #arrow-up-square {
    display: none !important;
  }
  #about-section {
    justify-content: center;
  }

  #about-me-section {
    width: inherit;
  }
}

@media only screen and (max-width: 320px) {
  #skill-studying a {
    height: 304px;
    font-size: 0.83em;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  /** Header Section*/

  #skill-studying {
    height: none;
    font-size: 0.8em;
  }

  /** Hero Section*/

  header {
    margin-top: -30px;
  }

  #hero {
    margin: 0px;
  }

  #hero #intro span.hi-light {
    font-size: 1.2em;
  }

  #type-of-dev {
    font-size: 2em;
    flex-flow: row wrap;
    margin: 40px 0px 60px 0px;
    line-height: 35px;
  }

  #hero_container {
    margin-top: 0px;
  }

  #more-about-me {
    justify-content: center;
  }

  #more-about-me-button {
    margin-right: 0;
  }

  /** Skills Section */
  .skill {
    width: auto !important;
  }
  #skills-container {
    width: 100%;
  }

  #skills-title-section {
    justify-content: center;
    margin-top: 30px;
  }

  #skills-title-section * {
    font-size: 50px;
    height: 150px;
  }
  #skill-container {
    flex-flow: column wrap;
  }
  #skill-languages,
  #skill-studying {
    min-width: 0px;
    padding-bottom: 100px;
  }

  /** About Section */
  #about-container {
    align-content: center;
    width: 100%;
  }
  .about-title-section-main {
    flex-flow: column wrap;
    flex: 1 1 auto;
    align-self: center;
  }
  #about-me-p {
    flex: 1 1 auto;
    min-width: 0px;
    font-size: 16px;

    margin-left: 0px;
    width: auto;
    min-width: 0px;
  }
  #about-me-p span {
    font-size: inherit;
  }
  #about-title-section * {
    font-size: 50px;
    height: 150px;
    margin-top: 30px;
  }
  #about-title {
    margin-top: 0px;
  }

  /** Contact Section */

  #contact-section {
    margin-top: 30px;
    justify-content: center;
  }
  #contact-title-section {
    justify-content: center;
  }
  #contact-title-section * {
    font-size: 49px;
    height: 150px;
    justify-content: center;
  }
  #nates-details {
    display: flex;
    flex-flow: column wrap;
    font-size: 0.4em;
  }
  #contact-socials {
    align-self: center;
    margin-left: 20px;
  }
  /** Footer */
  footer {
    width: 100%;
  }
  #footer-copy {
    font-size: 14px;
    align-items: center;
  }

  /** Other */
  #sideways-subject {
    display: none;
  }

  span.hi-light {
    font-size: 10px;
  }

  #right-arrow {
    display: none;
  }
}

/* Custom */
@media only screen and (min-width: 320px) and (max-width: 1204px) {
  #hero #intro {
    max-width: 100%;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  #skills-section {
    margin-top: 30%;
  }
}

/****************************Custom****************************/

.pls {
  position: absolute;
  width: 195px;
  height: 70px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 7px;
}

.pls::before,
.pls::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: inherit;
  z-index: -1;
  opacity: 0.7;
  border-radius: 7px;
}

.pls::before {
  animation: p 1s ease-in infinite;
}

/* .pls::after {
  animation: p 1.2s ease-out infinite;
} */

@keyframes p {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

header {
  display: flex;
  justify-content: space-between;
  color: var(--main-font-color);
  font-family: "Bruno Ace";
  width: 100%;
}

.h-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.name-logo {
  text-transform: uppercase;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
}

.nav-link {
  transition: color 0.3s, filter 0.3s 0.2s;
}
.nav-link:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 30px rgb(201, 122, 138));
  color: rgb(201, 122, 138);
}

#cor {
  width: 82px;

  /* font-family: "Rajdhani"; */
  font-style: normal;
  font-weight: 400;
  font-size: 37px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.04em;
}

#bitt {
  position: relative;
  width: 91px;
  top: -15px;
  /* font-family: "Rajdhani"; */
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  letter-spacing: 0.17em;
}

:root {
  --drk-hi-light: rgb(152 67 84);
}

.hi-light {
  color: #ff6c8a;
  text-shadow: 0px 0px 10px #ff6c8a;
  letter-spacing: 1px;
}

.hi-light-blue {
  color: rgb(57, 57, 240);
  text-shadow: 0px 0px 10px rgb(57, 57, 240);
  letter-spacing: 1px;
}

.restructuring:hover {
  cursor: pointer;
}

.icon {
  width: 76px;
  z-index: 1;
  position: relative;
  width: 50px;
}

.transition {
  transition: box-shadow 0.5s ease;
}

.flex_center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex_center_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill {
  box-shadow: 14px 14px black;
}
.skill:hover {
  box-shadow: 14px 14px 24px black;
}

#sql-nosql {
  font-weight: bold;
  border-radius: 7px;
  border: 2px solid white;
  font-size: 0.6em;
}

.min-width-studying {
  min-width: 290px;
}
.min-width-lang {
  min-width: 312px;
}

.hi-light-blue {
  color: rgb(56, 56, 245);
  text-shadow: 0px 0px 10px rgb(56, 56, 245);
  letter-spacing: 1px;
}

.restructuring_definition {
  position: absolute;
  border: 3px solid black;
  width: 23em;
  height: 11em;
  padding: 1em;
  background: #3e323a;
  z-index: 1;
  align-self: center;
}

.restructuring_definition dfn {
  text-decoration: underline;
}

.restructuring_definition p {
  line-height: 1.6em;
  margin-top: 1em;
}

.close_definition {
  float: right;
  cursor: pointer;
}

.restructuring {
  text-decoration: underline;
}

textarea {
  padding: 12px;
}

input {
  padding: 12px 12px 12px 30px;
}

.contact-strapline {
  font-size: 1.2em;
  color: grey;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contact-headline {
  font-size: 2em;
}
#contact-email {
  font-size: 4em;
}
.contact-section-hr {
  margin: 30px 0px 30px;
}

/* #nates-details::before {
  content: "";
  width: 30%;
  height: 3px;
  position: absolute;
  left: -106px;
  background-color: var(--main-font-color);
  top: 13px;
}

#nates-details::after {
  content: "";
  width: 30%;
  height: 3px;
  position: absolute;
  left: 215px;
  background-color: var(--main-font-color);
  top: 13px;
}

{
  position: "relative",
  top: "-116px",
  left: 146,
  transform: "rotate(270deg)",
  fontSize: "1.5em",
  letterSpacing: 5,
  minWidth: "270px",
} */

